import React, { useState, useEffect, useMemo, useCallback } from "react";
import { UploadItem, UploadManager } from "../components/UploadDropzone/uploadManager";
import { GalleryItemProps } from "../components/Create/Gallery/GalleryItem";
import { MediaListItem } from "@jconradi/tutu-porn-api-client/src/users";

type CreateStep = "choose" | "info" | "gallery" | "complete";

export interface CreateContextType {
    uploadManager: UploadManager;
    galleryItems: GalleryItemProps[];
    setGalleryItems: (val: GalleryItemProps[]) => void;
    step: CreateStep;
    setStep: (step: CreateStep) => void;
    reset: () => Promise<void>;
    isPaid: boolean;
    setIsPaid: (isPaid: boolean) => void;
    price: number;
    setPrice: (price: number) => void;
    galleryPreviewItems: number;
    setGalleryPreviewItems: (amt: number) => void;
    tags: string[];
    setTags: (tags: string[]) => void;
    createdMedia?: MediaListItem;
    setCreatedMedia: (createdMedia?: MediaListItem) => void;
}
export const CreateContext = React.createContext<CreateContextType>({
    uploadManager: new UploadManager(),
    galleryItems: [],
    setGalleryItems: (_) => {},
    step: "choose",
    setStep: _ => {},
    reset: async () => {},
    isPaid: false,
    setIsPaid: () => {},
    price: 0,
    setPrice: () => {},
    galleryPreviewItems: 0,
    setGalleryPreviewItems: () => {},
    tags: [],
    setTags: () => {},
    createdMedia: undefined,
    setCreatedMedia: () => {}
});

export const CreateContextProvider = (props: any) => {
    const [uploadManager, setUploadManager] = useState<UploadManager>(new UploadManager());
    const [galleryItems, setGalleryItems] = useState<GalleryItemProps[]>([]);
    const [ step, setStep ] = useState<CreateStep>("choose");
    const [ isPaid, setIsPaid ] = useState(false);
    const [ price, setPrice ] = useState(5);
    const [ galleryPreviewItems, setGalleryPreviewItems ] = useState(1);
    const [ tags, setTags ] = useState<string[]>([]);
    const [ createdMedia, setCreatedMedia ] = useState<MediaListItem>();

    const createGalleryItem = (upload: UploadItem): GalleryItemProps => ({
        id: upload.id.toString(),
        name: upload.file.name,
        onRemove: () => removeGalleryItem(upload.id.toString()),
        coverType: upload.file.type.startsWith("image") ? "photo" : "video",
        cover: upload.poster,
        uploadItem: upload
    })

    const onUploadAdded = (upload: UploadItem) => {
        setGalleryItems(items => {
            return items.concat(createGalleryItem(upload));
        })
    }

    const onUploadRemoved = (upload: UploadItem) => {
        setGalleryItems(items => items.filter(u => u.id !== upload.id));
        if (uploadManager.getUploads().length === 0) {
            reset();
        }
    }


    // const loadUploads = () => {
    //     const uploads = uploadManager.getUploads();
    //     const galleryItems = uploads.map(u => {
    //         return {
    //             id: u.id.toString(),
    //             name: u.file.name,
    //             onRemove: () => removeGalleryItem(u.id.toString()),
                
    //         }
    //     });
    //     setGalleryItems(galleryItems);
    // }

    const removeGalleryItem = (localUploadId: string) => {
        uploadManager.cancel(localUploadId);
    }

    const reset = async () => {
        await uploadManager.cancelAll();

        setGalleryItems([]);
        setUploadManager(new UploadManager());
        setCreatedMedia(undefined);
        setIsPaid(false);
        setPrice(5);
        setGalleryPreviewItems(1);
        setStep("choose");
        setTags([]);

    };
    

    useEffect(() => {
        if (uploadManager) {
            uploadManager.UploadAdded.on(onUploadAdded);
            uploadManager.UploadRemoved.on(onUploadRemoved);
        }

        // loadUploads();

        return () => {
            uploadManager.UploadAdded.off(onUploadAdded);
            uploadManager.UploadRemoved.off(onUploadRemoved);

        }
    }, [uploadManager]);

    const value = {
        uploadManager,
        galleryItems,
        setGalleryItems,
        step,
        setStep,
        reset,
        isPaid,
        setIsPaid,
        price,
        setPrice,
        galleryPreviewItems,
        setGalleryPreviewItems,
        tags,
        setTags,
        createdMedia,
        setCreatedMedia
    };

    return (
        <CreateContext.Provider value={value}>
            {props.children}
        </CreateContext.Provider>
    );
}