import { useRouter } from 'next/router';
import { ReactNode, useCallback, useContext } from 'react'
import {useDropzone} from 'react-dropzone'
import { AuthContext } from '../../contexts/AuthContext';
import { CreateContext } from '../../contexts/CreateContext';
import { login } from '../../util/account';
import { UploadDropzoneOverlay } from './UploadDropzoneOverlay';
import { UploadManager } from './uploadManager';

export interface UploadDropzoneProps {
    children: ReactNode;
}

export const UploadDropzone = ({ children }: UploadDropzoneProps) => {
    const { uploadManager, setStep, step } = useContext(CreateContext);
    const router = useRouter();
    const { isLoggedIn } = useContext(AuthContext);

    const onDrop = (acceptedFiles: File[]) => {
        if (!isLoggedIn) {
            login(router, "/create");
            return;
        }
        
        for (const file of acceptedFiles) {
            uploadManager.addFile(file);
        }

        const uploads = uploadManager.getUploads().length;

        // Forward to the right page in the upload process
        if (uploads > 1) {
            setStep("gallery");
        }
        else if (uploads === 1) {
            if (step === "choose") {
                setStep("info");
            }
            else if (step === "info") {
                setStep("gallery");
            }
        }


        router.push('/create');
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop, accept: {
            'image/*': [],
            'video/*': [],
        },
        noClick: true
    })
    
    return (
        <div {...getRootProps()} className="min-h-screen" >
            <input {...getInputProps()} />
            {isDragActive && <UploadDropzoneOverlay />}
            {children}
        </div>
    )
}