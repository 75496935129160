import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../contexts/AppContext";
import useDebounce from "../../hooks/useDebounce";
import { useSuggest } from "../../hooks/useSuggest";
import SearchIcon from "../../public/images/icons/search.svg";
import { Spinner } from "../Spinner/Spinner";
import { SearchDropdown } from "./SearchDropdown";
import CloseIcon from "../../public/images/icons/close.svg";
import cls from 'clsx';
import {useMediaQuery, useMediaQueries} from '@react-hook/media-query'
import useTranslation from "next-translate/useTranslation";
import { WatchContext } from "../../contexts/WatchContext";
import { useMediaParams } from "../Media/MediaFeed/useMediaParams";

export default function Search(){
    const ref = useRef<any>();
    const { search, setSearch, isScrolled, searchOpen } = useContext(AppContext);
    const mediaParams = useMediaParams();
    const { searchTags, setSearchTags } = useContext(WatchContext);
    const [ focused, setFocused ] = useState(false);
    const { t } = useTranslation('common');

    const debouncedSearch = useDebounce(search, 150);
    const suggest = useSuggest(debouncedSearch);
    const isDesktop = useMediaQuery('only screen and (min-width: 640px)');

    const hasSearch = search.trim() != "";

    const removeTag = (name: string) => {
        const newTags = [...searchTags];
        const idx = newTags.findIndex(t => t === name);

        if (idx === -1) {
            return;
        }

        newTags.splice(idx, 1);

        setSearchTags(newTags);
        setSearch('');
        mediaParams.setPage(undefined);
        window.scrollTo(0, 0);
    }

    if (searchOpen === false && isScrolled && isDesktop) {
        return null;
    }

    if (isScrolled && !searchOpen && isDesktop) {
        return null;
    }

    const desktopVisible = !isScrolled && isDesktop;

    return(
        <div className={cls('sm:block', {
            ['hidden']: isDesktop ? desktopVisible : !searchOpen,
        })}>
            <div className='bg-base-300 w-full pb-2 sm:pb-5 px-4 sm:border-b-[1px] sm:border-white/[0.16]'>
                <div className="form-control w-full">
                    <div className="relative border-white focus-within:border-purple-600 border-4 input-group
                     w-full max-w-[1144px] mx-auto bg-white rounded-2xl px-[3px] flex flex-row group">
                        <div className="flex flex-row flex-1 items-center gap-x-2 overflow-x-auto">
                            {searchTags?.map(t => {
                                return (
                                    <div key={t} className="bg-primary cursor-pointer 
                                    min-h-[32px] py-[4px] px-[9px] gap-x-2 items-center justify-center flex flex-row 
                                    text-center font-bold rounded-full text-[0.875rem] whitespace-nowrap">
                                        {t}
                                        <CloseIcon onClick={() => removeTag(t)} className="w-4 h-4 rounded-full text-white hover:bg-black" />
                                    </div>
                                )
                            })}

                            <input ref={ref} value={search} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}
                                onChange={(e) => setSearch(e.target.value)} type="text"
                                placeholder={t('searchPlaceholder') || ''}
                                className="placeholder-primary w-full min-w-[100px] font-bold bg-transparent w-full text-primary outline-none p-2" />

                        </div>
                        <button className="btn btn-square bg-transparent border-none flex-none">
                            {(!hasSearch || !suggest.isLoading) && <SearchIcon onClick={() => ref.current?.focus()} className='stroke-primary stroke-[2]'/>}
                            {suggest.isLoading && hasSearch && <Spinner className="text-primary" />}
                            
                        </button>
                        {focused &&
                            <SearchDropdown data={suggest.data?.data} clearSearch={() => setSearch('')} />
                        }
                        <div className='hidden group-focus-within:flex fixed z-[-1] top-[76px] left-0 bg-gray-400 opacity-20 w-screen h-screen'>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}