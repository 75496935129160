import {
    useQuery,
  } from '@tanstack/react-query'
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { suggest } from '@jconradi/tutu-porn-api-client/src/suggest';

export const useSuggest = (searchQuery: string) => {

    searchQuery = searchQuery.trim();

    const query = useQuery({
        queryKey: ['suggest', searchQuery],
        queryFn: () => suggest(searchQuery),
        enabled: searchQuery.length > 0,
        useErrorBoundary: true
    });

    return query;
}