import { IconContainer } from "../../Buttons/IconContainer"
import { UnderlinedButton } from "../../Buttons/UnderlinedButton"
import UserIcon from '../../../public/images/icons/user.svg';
import styles from './AccountDropdown.module.css';
import Link from "next/link";
import ArrowRight from '../../../public/images/icons/arrow_right.svg';
import { useCallback, useContext, useState } from "react";
import { useRouter } from "next/router";
import { login, signup } from "../../../util/account";
import { LoadingIconContainer } from "../../Buttons/LoadingIconContainer";
import { AppContext } from "../../../contexts/AppContext";
import cls from 'clsx';
import useTranslation from "next-translate/useTranslation";

export interface AccountDropdownProps {
    isLoading: boolean;
}
export const AccountDropdown = ({ isLoading }: AccountDropdownProps) => {
    const router = useRouter();
    const { isScrolled } = useContext(AppContext);
    const [ enableHover, setHoverEnabled ] = useState(true);
    const { t } = useTranslation('common');


    const handleSelected = () => {
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur()
        }

        setHoverEnabled(false);
      }

    const onInteractionEnded = () => {
        setHoverEnabled(true);
    }

    return (
        <div onMouseLeave={onInteractionEnded} onTouchEnd={onInteractionEnded} className={cls(`dropdown dropdown-bottom ${styles.account_dropdown}`, {
            ['dropdown-hover']: enableHover && !isLoading,
        })}>
            <UnderlinedButton disabled={isLoading} renderContainer={(props) => <label {...props} />} tabIndex={0}>
                <LoadingIconContainer hideText={isScrolled} label={t('auth.login')} icon={<UserIcon className="stroke-white w-8 h-8" />} loading={isLoading} />
            </UnderlinedButton>
            
            <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-300 rounded-box w-52">
                <li onClick={handleSelected}>
                    <button onClick={() => login(router)}>
                        <IconContainer responsiveText={false} className="gap-x-2" icon={<ArrowRight className="stroke-white w-6 h-6" />}
                         label={t('auth.login')} />
                    </button>
                </li>
                <li onClick={handleSelected}>
                    <button onClick={() => signup(router)}>
                        <IconContainer responsiveText={false} className="gap-x-2" icon={<UserIcon className="stroke-white w-6 h-6" />}
                         label={t('auth.signUp')} />
                    </button>
                </li>
            </ul>
        </div>
    )
}