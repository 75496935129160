import {
    useQuery,
  } from '@tanstack/react-query'
import { getTrendingTags } from '@jconradi/tutu-porn-api-client/src/tags'

export const useTrendingTags = () => {
    const query = useQuery({
        queryKey: ['tags', 'trending'],
        queryFn: async () => {
            const resp  = await getTrendingTags();

            return resp.data;
        }
    });

    return query;
}