import { IconContainer } from "../../Buttons/IconContainer"
import { UnderlinedButton } from "../../Buttons/UnderlinedButton"
import UserIcon from '../../../public/images/icons/user.svg';
import EditIcon from '../../../public/images/icons/edit.svg';
import styles from '../Unauthorized/AccountDropdown.module.css';
import Link from "next/link";
import LogoutIcon from '../../../public/images/icons/logout.svg';
import SettingsIcon from '../../../public/images/icons/settings.svg';

import { useCallback, useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { login, logout } from "../../../util/account";
import { AuthContext } from "../../../contexts/AuthContext";
import { CreateContext } from "../../../contexts/CreateContext";
import { AppContext } from "../../../contexts/AppContext";
import cls from 'clsx';
import useTranslation from "next-translate/useTranslation";

export const AuthAccountDropdown = () => {
    const router = useRouter();
    const authContext = useContext(AuthContext);
    const { isScrolled } = useContext(AppContext);
    const { uploadManager } = useContext(CreateContext);
    const [ enableHover, setHoverEnabled ] = useState(true);
    const { t } = useTranslation('common');

    const { username } = authContext.jwtData!;

    const handleSelected = () => {
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur()
        }

        setHoverEnabled(false);
      }

    const onInteractionEnded = () => {
        setHoverEnabled(true);
    }

    return (
        <div onMouseLeave={onInteractionEnded} onTouchEnd={onInteractionEnded} className={cls(`dropdown dropdown-bottom ${styles.account_dropdown}`, {
        })}>
            <UnderlinedButton renderContainer={(props) => <label {...props} />} tabIndex={0}>
                <IconContainer hideText={isScrolled} label={username} icon={<UserIcon className="stroke-white w-8 h-8" />} />
            </UnderlinedButton>
            
            <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-300 rounded-box w-52">
                <li onClick={handleSelected}>
                    <Link href={`/users/${authContext.jwtData?.username}`}>
                        <IconContainer responsiveText={false} className="gap-x-2" icon={<UserIcon className="stroke-white w-6 h-6" />} label={t('authMenu.viewProfile')} />
                    </Link>
                </li>
                <li onClick={handleSelected}>
                    <Link href={`/user/profile`}>
                        <IconContainer responsiveText={false} className="gap-x-2" icon={<SettingsIcon className="stroke-white w-6 h-6" />} label={t('authMenu.settings')} />
                    </Link>
                </li>
                <li onClick={() => (document.activeElement as any).blur()}>
                    <button onClick={() => logout(router, uploadManager)}>
                        <IconContainer responsiveText={false} className="gap-x-2" icon={<LogoutIcon className="stroke-white  w-6 h-6" />} label={t('auth.logout')} />
                    </button>
                </li>
            </ul>
        </div>
    )
}