import axios from "./axios";

export interface TagsResponse {
    mediaCount: number;
    name: string;
    slug: string;
}
export const getTags = () => {
    return axios.get<TagsResponse[]>('/tags');
}

export const getTrendingTags = () => {
    return axios.get<TagsResponse[]>('/tags/trending');
}