import xxhash from "xxhash-wasm";
import Queue from "promise-queue";
import { createUpload, deleteUpload, uploadFile } from "@jconradi/tutu-porn-api-client/src/upload";
import { TypedEvent } from "@jconradi/tutu-porn-components/util/typedEvent";
import { AxiosProgressEvent } from "axios";

export interface UploadProgress {
    progress: number;
    currentBytes: number;
    totalBytes: number;
    rate: number;
    estimate: number;
}

export type UploadId = string;

export type UploadItemStatus = "waiting" | "hashing" | "uploading" | "error" | "complete";

export interface UploadItem {
    /**
     * Local client id representing this upload
     */
    id: UploadId;
    /**
     * The local file being uploaded
     */
    file: File;
    /**
     * Hash, if available, of the local file being uploaded.
     */
    hash?: number;
    /**
     * Poster file representing the full natively sized upload
     */
    poster: string;
    status: UploadItemStatus;
    progress?: AxiosProgressEvent;
    /**
     * The byte position this upload started from
     */
    offset: 0;
    /**
     * The id of the upload from api server
     */
    remoteUploadId?: string;
    /**
     * Has this upload been cancelled before it can be processed
     */
    cancelled: boolean;
    abortController: AbortController;
    uploadUpdated: TypedEvent<void>;
}

export class UploadManager {
    public static MaxUploadSize = 8589934592 * 2; // 1GB * 2
    public static MaxSimultaneousHashes = 1;
    public static MaxSimultaneousUploads = 3;

    public readonly UploadUpdated = new TypedEvent<UploadItem>();
    public readonly UploadAdded = new TypedEvent<UploadItem>();
    public readonly UploadRemoved = new TypedEvent<UploadItem>();
    public readonly UploadsCompleted = new TypedEvent<void>();
    public readonly UploadsTotalProgress = new TypedEvent<UploadProgress>();

    // Prepares media for upload (generates resized cover images, etc...)
    private readonly prepareQueue = new Queue();
    // Calculats hash of files to determine if we should even attempt an upload
    private readonly hashQueue = new Queue(UploadManager.MaxSimultaneousHashes)
    // Performs actual transfer of bytes
    private readonly uploadQueue = new Queue(UploadManager.MaxSimultaneousUploads, undefined, {
        onEmpty: () => this.onAllUploadsComplete()
    });

    private uploads: { [uploadId: UploadId]: UploadItem } = {};

    private uploadId = 1;

    private totalProgress: UploadProgress = { 
        totalBytes: 0,
        currentBytes: 0,
        progress: 0,
        estimate: 0,
        rate: 0
    };

    constructor() {
    }

    getUpload(id: string) {
        return this.uploads[id];
    }

    getUploads() {
        return Object.values(this.uploads).filter(u => !u.cancelled);
    }

    getTotalProgress() {
        return this.totalProgress;
    }

    isComplete() {
        return Object.values(this.uploads).every(u => u.status === "complete");
    }

    updateTotalProgress() {
        const uploads = this.getUploads();
        const progressUploads = uploads
            .filter(u => u.status === 'uploading')

        const completeUploadsProgress = uploads
        .filter(u => u.status === 'complete' || u.status === 'error');

        const completeUploadsBytes = completeUploadsProgress.reduce((prev, curr) => prev + curr.file.size, 0);
        const progressBytes = progressUploads.reduce((prev, curr) => prev + (curr.progress?.loaded || 0) + curr.offset, 0);

        this.totalProgress.currentBytes = progressBytes + completeUploadsBytes;
        this.totalProgress.totalBytes = uploads.map(u => u.file).reduce((prev, curr) => prev + (curr.size), 0);
        this.totalProgress.progress = (this.totalProgress.currentBytes * 100) / this.totalProgress.totalBytes;
        this.UploadsTotalProgress.emit(this.totalProgress);
    }

    resetProgress() {
        this.totalProgress = {
            totalBytes: 0,
            currentBytes: 0,
            progress: 0,
            estimate: 0,
            rate: 0
        };
    }

    emitUploadUpdated(upload: UploadItem) {
        this.UploadUpdated.emit(upload);
        upload.uploadUpdated.emit();
    }

    async cancel(uploadId: string, deleteServerUpload: boolean = false) {
        const upload = this.uploads[uploadId];

        if (!upload || upload?.cancelled) {
            return;
        }

        upload.cancelled = true;
        upload.abortController.abort();

        this.emitUploadUpdated(upload);

        this.UploadRemoved.emit(upload);

        try {
            if (deleteServerUpload && upload.remoteUploadId) {
                await deleteUpload(upload.remoteUploadId);
            }
        }
        catch (err) {
            console.error(err);
        }

        delete this.uploads[uploadId];

        this.updateTotalProgress();
    }

    async cancelAll() {
        const uploads = Object.values(this.uploads);

        await Promise.all(uploads.map(u => this.cancel(u.id)));
    }

    async hashFile(uploadItem: UploadItem): Promise<number | undefined> {
        try {
            const { file } = uploadItem;
            const {
                create32,
            } = await xxhash();

            const hash = create32();

            const filePrototype = File.prototype as any;
            var blobSlice = filePrototype.slice || filePrototype.mozSlice || filePrototype.webkitSlice,
                chunkSize = 2097152 * 64,                             // Read in chunks of 2MB
                chunks = Math.ceil(file.size / chunkSize),
                currentChunk = 0,
                fileReader = new FileReader();

            const promise = new Promise<number>((resolve, reject) => {
                fileReader.onload = function (e: any) {
                    if (uploadItem.cancelled) {
                        reject(new Error('Hash canceled'));
                    }
                    hash.update(e.target.result);
                    currentChunk++;

                    if (currentChunk < chunks) {
                        loadNext();
                    } else {
                        resolve(hash.digest());
                    }
                };

                fileReader.onerror = function () {
                    reject();
                };
            })

            const loadNext = () => {
                var start = currentChunk * chunkSize,
                    end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;

                fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
            }

            loadNext();

            return promise;
        }
        catch (err) {
            console.error({err});
            return undefined;
        }
    }

    async prepare(upload: UploadItem) {
        if (upload.cancelled) {
            return;
        }

        this.hashQueue.add(() => this.processHash(upload));
    }

    async processHash(upload: UploadItem) {
        try {
            if (upload.cancelled) {
                return;
            }
            if (upload.hash === undefined) {
                upload.status = 'hashing';
                this.emitUploadUpdated(upload);
        
                const hash = await this.hashFile(upload);
                upload.hash = hash;
            }

            this.uploadQueue.add(() => this.processUpload(upload));
        }
        catch (err) {
            upload.status = 'error';
            this.emitUploadUpdated(upload);
            this.updateTotalProgress();
        }
    }

    async processUpload(upload: UploadItem) {
        try {
            if (upload.cancelled) {
                return;
            }

            upload.status = 'uploading';
            this.emitUploadUpdated(upload);

            const uploadResp = await createUpload(upload.hash?.toString());

            upload.remoteUploadId = uploadResp.data.id;
            upload.offset = uploadResp.data.uploadOffset;
            this.emitUploadUpdated(upload);

            // This file already exists on the server in its entirety
            if (uploadResp.data.uploadOffset !== upload.file.size) {
                await uploadFile(upload.file, uploadResp.data.id, uploadResp.data.uploadOffset, upload.abortController.signal, (progress: AxiosProgressEvent) => {
                    upload.progress = progress;
                    this.emitUploadUpdated(upload);
                    this.updateTotalProgress();
                });
            }


            this.processUploadComplete(upload)
        }
        catch (e) {
            console.log({e});
            upload.status = "error";
            this.emitUploadUpdated(upload);
            this.updateTotalProgress();
        }
    }

    processUploadComplete(upload: UploadItem) {
        upload.status = "complete";
        this.emitUploadUpdated(upload);

        this.updateTotalProgress();
    }

    onAllUploadsComplete() {
        this.updateTotalProgress();


        if (this.isComplete()) {
            this.UploadsCompleted.emit();
        }
    }

    getUploadFromFile(file: File): UploadItem | undefined {
        return Object.values(this.uploads).find(u => {
            if(u.file.name === file.name && u.file.size === file.size && u.file.lastModified === file.lastModified)
            {
                return true;
            }
            return false;
        })
    }

    retryAll() {
        Object.values(this.uploads).filter(u => u.status === "error")
            .forEach(u => this.retry(u.id));
    }

    retry(uploadId: string) {
        const upload = this.uploads[uploadId];

        if (upload === undefined) {
            return;
        }

        if (upload.status != "error") {
            return;
        }

        upload.status = "waiting";
        this.emitUploadUpdated(upload);

        this.updateTotalProgress();

        this.prepareQueue.add(() => this.prepare(upload));
    }

    addFile(file: File): void {
        if (file.size > UploadManager.MaxUploadSize) {
            throw new Error('File too large to add')
        }

        const existingUpload = this.getUploadFromFile(file);
        
        if (existingUpload) {
            return;
        }

        const upload: UploadItem = {
            id: (this.uploadId++).toString(),
            file,
            status: "waiting",
            cancelled: false,
            abortController: new AbortController(),
            offset: 0,
            uploadUpdated: new TypedEvent<void>(),
            poster: URL.createObjectURL(file)
        };

        this.uploads[upload.id.toString()] = upload;
        this.UploadAdded.emit(upload);

        this.updateTotalProgress();

        this.prepareQueue.add(() => this.prepare(upload));
    }
}