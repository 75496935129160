import styles from "./UnderlinedButton.module.css";
import cn from 'clsx';
import React, { ReactElement, ReactHTML, ReactNode } from "react";

interface UnderlinedButtonProps {
    className?: string;
    underlineType?: 'primary' | 'secondary';
    children?: ReactNode | ReactNode[];
    renderContainer?: (props: any) => JSX.Element;
    [rest: string]: any;
}
export const UnderlinedButton = ({ renderContainer = (props) => <button {...props} />,
    children,
    underlineType = 'primary',
    className,
    ...rest }: UnderlinedButtonProps) => {
    const cls = cn(styles.underlined_button, className, {
        [styles.primary]: underlineType === 'primary',
        [styles.secondary]: underlineType === 'secondary',
    });

    return renderContainer({
        children,
        className: cls,
        ...rest
    });
}