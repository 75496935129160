import { ReactElement, useCallback, useContext, useEffect } from "react"
import { Navigation } from "@jconradi/tutu-porn-components/components/Navigation/Navigation"
import Footer from "@jconradi/tutu-porn-components/components/Footer";
import styles from './Layout.module.css';
import { UploadDropzone } from "@jconradi/tutu-porn-components/components/UploadDropzone/UploadDropzone";
import { CreateContextProvider } from "@jconradi/tutu-porn-components/contexts/CreateContext";
import { SkeletonTheme } from "react-loading-skeleton";
import { useRouter } from "next/router";
import { QueryError } from "@jconradi/tutu-porn-components/components/Errors/QueryError/QueryError";
import { AppContext, AppContextProvider } from "@jconradi/tutu-porn-components/contexts/AppContext";
import cls from 'clsx';
import { PageError } from "@jconradi/tutu-porn-components/components/Errors/PageError/PageError";
import { ErrorBoundary } from "react-error-boundary";
import Head from "next/head";
import { AuthContext } from "@jconradi/tutu-porn-components/contexts/AuthContext";
import { LayoutLoadingBar } from "./LayoutLoadingBar";
import { SilentAuth } from "@jconradi/tutu-porn-components/components/Auth/SilentAuth";
import { BannerAd } from "@jconradi/tutu-porn-components/components/Ads/Exoclick/BannerAd";
import { TutuDefaultSeo } from "../Seo/TutuDefaultSeo";

export interface LayoutProps {
    children: ReactElement[] | ReactElement;
}
const Layout = ({ children }: LayoutProps) => {
    const router = useRouter();
    const { jwtData } = useContext(AuthContext);

    return (
        <>

            <ErrorBoundary
                onReset={() => { window.location.reload() }}
                resetKeys={[router.query]}
                fallbackRender={({ resetErrorBoundary }) => (
                    <PageError>
                        <button className="btn" onClick={() => resetErrorBoundary()}>Reload Page</button>
                    </PageError>
                )}>
                <TutuDefaultSeo />
                <Head>
                    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                    <link rel="manifest" href="/site.webmanifest" />
                </Head>
                <LayoutLoadingBar />
                <UploadDropzone>
                    <Navigation />
                    <section className={cls(styles.layout_wrapper)}>
                        <QueryError>
                            {children}
                        </QueryError>
                    </section>
                    <Footer />
                </UploadDropzone>
            </ErrorBoundary>

        </>

    )
}

export const getLayout = (page: ReactElement | ReactElement[]) => {
    return (
        <SkeletonTheme baseColor="#242425" highlightColor="#3a3b3f">
            <CreateContextProvider>
                <AppContextProvider>
                    <Layout>
                        {page}
                    </Layout>
                </AppContextProvider>
            </CreateContextProvider>
        </SkeletonTheme>
    )
}