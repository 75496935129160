import { DefaultSeo } from 'next-seo';
import { useRouter } from 'next/router';
import SEO from '../../next-seo.config';
import i18n from '../../i18n';

export const TutuDefaultSeo = ({ ...props }) => {
    const router = useRouter();

    const imageUrl = `${process.env.NEXT_PUBLIC_SCREENSHOT_ENDPOINT}/screenshot?url=https://${process.env.NEXT_PUBLIC_VERCEL_URL}${router.asPath}&waitForFrame=4000&viewport=1200,627`;

    const seo = SEO as any;
    seo.openGraph.images = [
        {
          url: imageUrl,
        },
      ];
    seo.url = process.env.NEXT_PUBLIC_SITE_URL + router.asPath;
    seo.openGraph = { ...seo.openGraph, ...props.openGraph, }

    const langs = i18n.languages;
    seo.languageAlternates = Object.keys(langs).map((l: any) => {
      let path = router.asPath;
      if (l != 'en') {
        path = '/' + l + path;
      }

      path = process.env.NEXT_PUBLIC_SITE_URL + path;

      return {
        hrefLang: l,
        href: path
      };
    });

    return (
        <DefaultSeo {...SEO} {...props} />
    )
}