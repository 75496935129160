import TwitterIcon from '../../public/images/icons/social/twitter.svg';
import RedditIcon from '../../public/images/icons/social/reddit.svg';
import DiscordIcon from '../../public/images/icons/social/discord.svg';
import cls from 'clsx';

export const SocialContacts = ({ className }: any) => {
    return (
        <>
        <a href="https://twitter.com/porntutu" target="_blank" rel="noreferrer">
            <TwitterIcon className={className} />
        </a>
        <a href="https://www.reddit.com/r/tutu_porn/" target="_blank" rel="noreferrer">
            <RedditIcon className={className} />
        </a>
        <a href="https://discord.gg/kjbPYmFdzk" target="_blank" rel="noreferrer">
            <DiscordIcon className={className} />
        </a>
        </>
    )
}