import Link from "next/link"
import TwitterIcon from '../../public/images/icons/social/twitter.svg';
import RedditIcon from '../../public/images/icons/social/reddit.svg';
import DiscordIcon from '../../public/images/icons/social/discord.svg';
import { SocialContacts } from "./SocialContacts";

export default function Footer(){
    return(
        <div className="w-full bg-base-300">
            <div className="max-w-[1144px] mx-auto flex text-md flex-col gap-0 md:gap-5 md:flex-row p-0 md:p-5 items-center">
                <p className="text-gray-600 text-sm md:text-base text-center md:text-start w-full md:w-[40%] border-b-2 md:border-b-0 border-white/[0.16] py-5">
                    © Copyright {new Date().getFullYear()} Tutu Media LLC
                </p>
                <div className="flex flex-wrap justify-center items-center md:justify-start text-sm md:text-base gap-x-5 w-full md:w-[45%] border-b-2 md:border-b-0 border-white/[0.16] py-5">
                    <Link className="" href="/notice2257">2257 Statement</Link>
                    <Link className="" href="/terms">Terms</Link>
                    <Link href="/dmca" className="">DMCA</Link>
                    <Link href="/privacy" className="">Privacy Policy</Link>
                </div>
                <div className="flex justify-center flex-nowrap md:flex-wrap gap-5 w-full  md:w-[15%] py-5">
                    <SocialContacts className="w-4 sm:w-6 sm:h-6 h-4 text-primary" />
                </div>
            </div>
        </div>
    )
}