import { NextRouter, Router } from "next/router"
import { UploadManager } from "@jconradi/tutu-porn-components/components/UploadDropzone/uploadManager";

const getLocale = (router: NextRouter) => {
    let locale = router.locale;

    if (locale == 'zh') {
        locale = 'zh-CN';
    }

    return locale;
}

export const logout = async (router: NextRouter, uploadManager: UploadManager) => {
    await uploadManager.cancelAll();
    
    router.push('/api/auth/logout');
}

export const login = (router: NextRouter, from?: string) => {
    router.push(`/api/auth/login?returnTo=${from || window.location}&locale=${getLocale(router)}`);
}

export const signup = (router: NextRouter, from?: string) => {
    router.push(`/api/auth/signIn?returnTo=${from || window.location}&locale=${getLocale(router)}`)
}