import { ReactElement } from "react";
import cn from 'clsx';

export interface IconContainerProps {
    icon: ReactElement;
    label: string;
    className?: string;
    responsiveText?: boolean;
    hideText?: boolean;
    labelClassName?: string;
}
export const IconContainer = ({ icon, label, hideText = false, responsiveText = true, className, labelClassName }: IconContainerProps) => {
    
    return <span className={`flex flex-row items-center justify-center gap-x-1 ${className || ''}`}>
        <>
        {icon}
        <span className={cn('font-bold', {
            ['hidden']: hideText,
            ['hidden sm:inline']: responsiveText && !hideText,
        }, labelClassName)}>{label}</span>
        </>
    </span>
}