import axios from 'axios';
import urlJoin from 'url-join';

export interface TutuModelVideo {
    name: string;
    description: string;
    slug: string;
    videoInformation: {
        width: number;
        height: number;
        durationInSeconds:  number;
    };
    model: {
        name: string;
        photoUrl: string;
        bannerUrl: string;
    };
    trailerUrl: string;
    thumbUrl: string;
    coverUrl: string;
}

export const getFeaturedTutuVideos = async () => {
    const resp = await axios.get('https://api.tutu.services/featured');

    return resp.data.videos as TutuModelVideo[];
}

export const getTutuModelsUrl = (path: string, locale: string = "en") => {
    const base = process.env.NEXT_PUBLIC_TUTU_MODELS_API_URL || `https://tutuporn.com`;
    
    return urlJoin(base, locale, path);
}