import Logo from '../../../public/images/logos/logo_vertical.png';
import Image from "next/image";
import Link from 'next/link';
import cls from 'clsx';

export const PageError = ({ className, children }: any) => {
    return (
        <div className={cls("text-center", className)}>
            <Image className="grayscale mx-auto w-48 md:w-64" src={Logo} alt="Not Found Logo" />
            <h1 className="text-2xl font-bold my-4 leading-[3rem]">Something Went Wrong</h1>
            {children}
        </div>
    )
}