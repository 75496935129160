import { AxiosProgressEvent } from 'axios';
import axios from './axios';
import { MediaListItem } from './users';

export interface CreateUploadResponse {
    id: string;
    uploadOffset: 0;
}
export const createUpload = (hash?: string) => {
    return axios.post<CreateUploadResponse>('/uploads', { hash });
}

export const uploadFile = (file: File, uploadId: string, uploadStart: number, signal: AbortSignal, onProgress?: (progress: AxiosProgressEvent) => void) => {
    const form = new FormData();
    form.append(uploadId, file.slice(uploadStart), uploadId);

    return axios.put(`/uploads/${uploadId}`, form, {
        headers: {
            'Content-Range': `bytes=${uploadStart}-${file.size}/${file.size}`
        },
        signal,
        onUploadProgress: onProgress
    });
}

export const deleteUpload = (uploadId: string) => {
    return axios.delete(`/uploads/${uploadId}`);
}

export interface CompleteUploadBody {
    isPaid: boolean;
    price?: number;
    keepAudio: boolean;
    tags: string[]
}
export const completeUpload = (uploadId: string, body: CompleteUploadBody) => {
    return axios.post(`/uploads/${uploadId}/complete`, body);
}

export interface CompleteGalleryBody extends CompleteUploadBody {
    uploads: string[];
}
export const completeGallery = (body: CompleteGalleryBody) => {
    return axios.post<MediaListItem>(`/gallerys`, body);
}