import Link from 'next/link';
import styles from './Navigation.module.css';
import Logo from '../../public/images/logos/logo_horizontal.png';
import LogoVertical from '../../public/images/logos/logo_vertical.png';
import Image from 'next/image';
import { UnderlinedButton } from '../Buttons/UnderlinedButton';
import { AccountDropdown } from './Unauthorized/AccountDropdown';
import { IconContainer } from '../Buttons/IconContainer';
import UploadIcon from '../../public/images/icons/upload.svg';
import { useContext, useEffect, useState } from 'react';
import { AuthAccountDropdown } from './Authorized/AuthAccountDropdown';
import { AuthContext } from '../../contexts/AuthContext';
import SearchIcon from '../../public/images/icons/secondary-search.svg';
import Search from './Search';
import SubMenu from './SubMenu';
import UnderLinedHover from '../Buttons/UnderLinedHover';
import Hamburger from '../../public/images/icons/hamburger.svg'
import LogOutIcon from '../../public/images/icons/logout.svg'
import UserIcon from '../../public/images/icons/user.svg';
import { AppContext } from '../../contexts/AppContext';
import { useRouter } from 'next/router';
import cls from 'clsx';
import ArrowRight from '../../public/images/icons/arrow_right.svg';
import { login, logout, signup } from '../../util/account';
import { CreateContext } from '../../contexts/CreateContext';
import { MiniNavLinks } from './MiniNavLinks';
import { LanguageDropdown } from './LanguageDropdown';
import useTranslation from 'next-translate/useTranslation';
import { getTutuModelsUrl } from '../../services/api/tutu-models/tutu-models';

export const Navigation = () => {
    const { isLoggedIn, isLoading } = useContext(AuthContext);
    const { open, setOpen, searchOpen, setSearchOpen, isScrolled } = useContext(AppContext);
    const { uploadManager } = useContext(CreateContext);
    const router = useRouter();
    const { t, lang } = useTranslation('common')

    useEffect(() => {
        setOpen(false);
    }, [router])

    return (
        <>
            <nav className={styles.nav}>
                    <div className={styles.nav_wrapper}>
                        <div className='flex flex-row items-center justify-center h-[72px]'>
                        
                            <button onClick={() => setOpen(!open)} className={open?'hidden':'md:hidden h-full'}>
                                <Hamburger/>
                            </button>
                            <button className='hidden md:block peer h-full w-14'>
                                <Hamburger/>
                            </button>
                            <SubMenu open={open}/>
                            <Link href="/" className={open? 'hidden':"btn btn-ghost h-full p-0 mx-2 hover:bg-base-300"}>
                                <>
                                <Image height={54} width={150} src={Logo} alt="Tutu Porn Logo" priority className='hidden sm:inline'/>
                                <Image height={56} width={40} src={LogoVertical} alt="Tutu Porn Logo" priority className='sm:hidden'/>
                                </>
                            </Link>
                        </div>
                        <div className="hidden md:flex h-[72px] items-center justify-center flex-row gap-3 flex-1">
                            <UnderLinedHover href={getTutuModelsUrl("/", router.locale)} label={t('tutuModels')}/>
                            <div className='hidden xl:flex gap-3'>
                                <UnderLinedHover href="/browse?order=trending" label={t('trending')}/>
                                <UnderLinedHover href="/creators" label={t('creators')}/>
                                <UnderLinedHover href="/browse?type=video&order=trending" label={t('videos')}/>
                                <UnderLinedHover href="/tags" label={'#' + t('tags')}/>
                                <UnderLinedHover href="/browse?type=photo&order=trending" label={t('images')}/>
                            </div>
                            <UnderLinedHover href="/advertise" label={t('advertise')}/>
                            <UnderLinedHover href="https://tutu69tv.com" label={t('tutuLive')}/>
                        </div>
                        <div className={open? 'hidden':'flex flex-row items-center justify-center'}>

                            <UnderlinedButton className={cls("", {
                                ['sm:hidden']: !isScrolled,
                                ['visible']: isScrolled,
                                ['ring-2 ring-outline']: searchOpen
                            })} onClick={() => setSearchOpen(!searchOpen)} renderContainer={(props) => <button {...props}/>} underlineType="secondary">
                                <IconContainer hideText={isScrolled} label={t('search')} icon={<SearchIcon className='stroke-white stroke-[4] w-8 h-8'/>} />
                            </UnderlinedButton>

                            <LanguageDropdown />

                            <UnderlinedButton renderContainer={(props) => <Link {...props} />} href="/create" underlineType="secondary">
                                <IconContainer hideText={isScrolled}  label={t('create')} icon={<UploadIcon className="stroke-white w-8 h-8" />} />
                            </UnderlinedButton>

                            {(isLoggedIn && !isLoading) && <AuthAccountDropdown /> }
                            {(!isLoggedIn || isLoading) && <AccountDropdown isLoading={isLoading} />}
                        </div>
                    </div>
                {!open && <Search />}
                <MiniNavLinks />
            </nav>

            <div className={open?'md:hidden fixed z-40 bottom-0 w-screen':'hidden'}>
                {isLoggedIn &&
                    <>
                        <div className='btn bg-base-300 rounded-none w-[50%] border-[1px] border-white/[0.16] h-16'>
                            <UnderlinedButton renderContainer={(props) => <Link {...props} />} href="/create" underlineType="secondary">
                                <IconContainer responsiveText={false} className="gap-x-2" icon={<UploadIcon className="stroke-white w-8 h-8 stroke-[3px]" />} label="Create" />
                            </UnderlinedButton>
                        </div>
                        <div className='btn bg-base-300 rounded-none w-[50%] border-[1px] border-white/[0.16] h-16'>
                            <UnderlinedButton onClick={() => logout(router, uploadManager)} underlineType="primary">
                                <IconContainer responsiveText={false} className="gap-x-2" icon={<LogOutIcon className="stroke-white stroke-[3] w-8 h-8" />} label="Log out" />
                            </UnderlinedButton>
                        </div>
                    </>
                }
                {!isLoggedIn &&
                    <>
                        <div className='btn bg-base-300 rounded-none w-[50%] border-[1px] border-white/[0.16] h-16'>
                            <UnderlinedButton onClick={() => login(router)} underlineType="primary">
                                <IconContainer responsiveText={false} className="gap-x-2" icon={<ArrowRight className="stroke-white w-6 h-6 stroke-[3px]" />} label="Login" />
                            </UnderlinedButton>
                        </div>
                        <div className='btn bg-base-300 rounded-none w-[50%] border-[1px] border-white/[0.16] h-16'>
                            <UnderlinedButton onClick={() => signup(router)} underlineType="secondary">
                                <IconContainer responsiveText={false} className="gap-x-2" icon={<UserIcon className="stroke-white w-6 h-6 stroke-[3px]" />} label="Sign Up" />
                            </UnderlinedButton>
                        </div>
                    </>
                }

            </div>
        </>
    )
}