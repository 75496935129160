import Link from "next/link"
import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import ArrowRight from "../../public/images/icons/arrow-right.svg";
import cls from 'clsx';
import { WatchContext } from "../../contexts/WatchContext";

export function RoundedLinkButton({tag, children, className, onClick }:{tag:string, children?: any, className?: string, onClick?: any}){
    const { addSearchTag } = useContext(WatchContext);

    return(
        <div onClick={() => {
            if (onClick) {
                onClick();
            }

            addSearchTag(tag);
        }}
            className={cls(`btn-primary cursor-pointer inline-block py-[4px] px-[9px] text-center
             flex flex-col items-center justify-center  font-bold rounded-full text-[0.875rem]`, className)}>
            {tag}
            {children}
        </div>
    )
}

export function MovingLinkButton({tag, href, ...rest}:{tag:string, href:string, [rest: string]: any}){
    return(
        <Link {...rest}
        className='flex-shrink-0 flex flex-row gap-2 gap-x-3 font-bold hover:text-primary ease-in-out duration-300 group'
        href={href}
        >
            <ArrowRight className="stroke-primary group-hover:translate-x-2 ease-in-out duration-300" />
            {tag}
        </Link>
    )
}