import Link from "next/link";
import cls from 'clsx';
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { getTutuModelsUrl } from "../../services/api/tutu-models/tutu-models";

export const MiniNavLinks = ({ className }: any) => {
    const { t } = useTranslation('common');
    const router = useRouter();

    return (
        <div className={cls(`bg-base-300 flex md:hidden gap-x-4 justify-around items-center w-screen py-4 px-10 border-b-[1px] border-t-[1px] border-white/[0.16]`, className)}>
            <Link href={getTutuModelsUrl("", router.locale)} className='text-sm font-bold text-center'>{t('tutuModels')}</Link>
            <Link href="/advertise" className='text-sm font-bold text-center'>{t('advertise')}</Link>
            <Link href="https://tutu69tv.com" className='text-sm font-bold text-center'>{t('tutuLive')}</Link>
        </div>
    );
}