import axios from "./axios";
import { TagsResponse } from "./tags";
import { MediaUser } from "./users";


export type SuggestResponse = {
    tags: TagsResponse[];
    users: MediaUser[];
}
export const suggest = (query: string) => {
    return axios.get<SuggestResponse>(`/suggest/search`, {
        params: { query }
    })
}