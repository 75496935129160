import { useContext, useEffect } from "react";
import { BooleanParam, NumberParam, StringParam, useQueryParam, withDefault } from "use-query-params";
import { AppContext } from "../../../contexts/AppContext";
import { MediaViewType } from "../MediaFilter/MediaViewType";
import { MediaSearchOrder } from "@jconradi/tutu-porn-api-client/src/media";

export const DefaultCount = 36;

export const useMediaParams = (defaults: any = {}) => {
    const [order, setOrder] = useQueryParam('order', withDefault(StringParam, defaults.order || MediaSearchOrder.Top));
    const [type, setType] = useQueryParam('type', withDefault(StringParam, defaults.type));
    const [view, setView] = useQueryParam('view', withDefault(StringParam, defaults.view || MediaViewType.List));
    const [verified, setVerified] = useQueryParam('verified', withDefault(BooleanParam, defaults.verified || undefined));
    const [horizontal, setHorizontal] = useQueryParam('horizontal', withDefault(BooleanParam, defaults.horizontal || undefined));
    const [vertical, setVertical] = useQueryParam('vertical', withDefault(BooleanParam, defaults.vertical || undefined));
    const [page, setPage] = useQueryParam('page', withDefault(NumberParam, defaults.page || 1));
    const [count, setCount] = useQueryParam('count', withDefault(NumberParam, defaults.count || DefaultCount));
    const [transcoding, setTranscoding] = useQueryParam('transcoding', withDefault(BooleanParam, defaults.transcoding || undefined));
    const [unapproved, setUnapproved] = useQueryParam('unapproved', withDefault(BooleanParam, defaults.unapproved || undefined));


    return {
        order,
        setOrder,
        type,
        setType,
        view,
        setView,
        verified,
        setVerified,
        horizontal,
        setHorizontal,
        vertical,
        setVertical,
        page,
        setPage,
        count,
        setCount,
        transcoding,
        setTranscoding,
        unapproved,
        setUnapproved
    }
}