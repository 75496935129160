import {RoundedLinkButton, MovingLinkButton} from '../Buttons/LinkButton';
import { ReactNode, useContext, useEffect, useState } from 'react';
import OpenIcon from '../../public/images/icons/plus-circle.svg'
import CloseIcon from '../../public/images/icons/minus-circle.svg'
import TrendingIcon from '../../public/images/icons/flash.svg'
import GifsIcon from '../../public/images/icons/gifs.svg'
import ImagesIcon from '../../public/images/icons/images.svg'
import CreatorsIcon from '../../public/images/icons/trending.svg'
import InfosIcon from '../../public/images/icons/infos.svg'
import * as Routes from "./Routes";
import cls from 'clsx';
import { useRouter } from 'next/router';
import { MiniNavLinks } from './MiniNavLinks';
import { useTrendingTags } from '../../hooks/useTrendingTags';
import { AppContext } from '../../contexts/AppContext';
import Image from 'next/image';
import LogoVertical from '../../public/images/logos/logo_vertical.png';
import { IconContainer } from '../Buttons/IconContainer';
import CloseRed from '../../public/images/icons/close-red.svg'
import useTranslation from 'next-translate/useTranslation';

export default function SubMenu({open}:{open:boolean}){
    const router  = useRouter();
    const [ hoverEnabled, setHoverEnabled ] = useState<boolean>(true);
    const { setOpen } = useContext(AppContext);

    const closeList = () => {
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur()
          }
  
        setHoverEnabled(false);
    }

    useEffect(() => {
        setHoverEnabled(true);
    }, []);

    return(
        <div onMouseLeave={() => setHoverEnabled(true)} className={`${open?'fixed z-20 flex justify-center w-screen h-screen top-0 left-0 mt-[124px] md:mt-[72px] md:h-[calc(100vh-200px)] bg-base-300 pb-12': cls('hidden fixed z-40 h-screen md:h-[calc(100vh-200px)] w-screen bg-base-300 left-0 top-[72px]', {
            ['sm:peer-hover:flex sm:hover:flex']: hoverEnabled === true
        })}`}>
            {
                open &&
                <div className='fixed flex flex-col items-center w-screen h-screen top-0 left-0 overflow-y-scroll overscroll-contain bg-base-300'>
                    <div className="sticky top-0">
                        <div className="flex justify-between items-center px-2 bg-base-300">
                            <Image height={56} width={40} onClick={() => setOpen(false)} src={LogoVertical} alt="Tutu Porn Logo"
                                className="m-2" />
                            <button onClick={() => setOpen(!open)} className={'bg-secondary w-8 h-8 rounded-md mr-2'}>
                                <IconContainer label={''} icon={<CloseRed />} />
                            </button>
                        </div>
                        <MiniNavLinks className="m-0 p-0" />
                    </div>
                    <CompactList />
                </div>
            }
            <OpenList closeList={closeList} />
        </div>
    )
}

export function OpenList({closeList }:{ closeList: any}){
    const query = useTrendingTags();
    const { t, lang } = useTranslation('common')

    return(
        <div className={`hidden md:flex flex-row mx-auto max-w-[1144px] text-base`}>
            <SubMenuContainer title={t('trendingTags')} containerClassName="flex flex-row flex-wrap gap-2 p-3">
            {!query.isLoading && query.data?.map(tag => {
                return (
                    <RoundedLinkButton onClick={closeList} key={tag.name} tag={tag.name} />
                )
            }).slice(0, 30)}
            </SubMenuContainer>
            <SubMenuContainer title={t('videos')} containerClassName="flex-col">
                {Routes.VideoRoutes.map(route => {
                    return (
                        <MovingLinkButton onClick={closeList} key={route.href} tag={t(route.titleKey)} href={route.href} />
                    )
                })}
            </SubMenuContainer>
            <SubMenuContainer title={t('images')} containerClassName="flex-col">
                {Routes.PhotoRoutes.map(route => {
                    return (
                        <MovingLinkButton onClick={closeList} key={route.href} tag={t(route.titleKey)} href={route.href} />
                    )
                })}
            </SubMenuContainer>

            <SubMenuContainer title={t('creators')} containerClassName="flex-col">
                {Routes.CreatorsRoutes.map(route => {
                    return (
                        <MovingLinkButton onClick={closeList} key={route.href} tag={t(route.titleKey)} href={route.href} />
                    )
                })}
            </SubMenuContainer>

            <SubMenuContainer title={t('info')} containerClassName="flex-col">
                {Routes.InfoRoutes.map(route => {
                    return (
                        <MovingLinkButton onClick={closeList} key={route.href} tag={t(route.titleKey)} href={route.href} />
                    )
                })}
            </SubMenuContainer>
        </div>
    )
}

const SubMenuContainer = ({ title, children, containerClassName }: any) => {
    return (
        <div className='flex flex-col h-full w-1/5 items-start'>
            <h3 className='p-3 font-bold'>{title}<div className='bg-primary h-[5px] rounded-full'></div></h3>
            <div className={cls('flex gap-2 p-3', containerClassName)}>
                {children}
            </div>
        </div>
    )
}

export function CompactList(){
    const query = useTrendingTags();
    const { t } = useTranslation('common');

    return(
        <div className='flex md:hidden flex-col gap-5 pb-36 w-full'>
            <div className='flex flex-col gap-1 px-3 py-5'>
                <Collapsible title={t('trendingTags')}
                    icon={<TrendingIcon/>}>
                    <div className='flex flex-wrap gap-2 p-3'>
                        {!query.isLoading && query.data?.map(tag => {
                            return (
                                <RoundedLinkButton  key={tag.name} tag={tag.name} />
                            )
                        }).slice(0, 20)}
                    </div>
                </Collapsible>
                <Collapsible title={t('videos')}
                    icon={<GifsIcon/>}>
                    <div className='grid grid-cols-2 gap-2 p-3'>
                        {Routes.VideoRoutes.map(route => {
                            return(
                                <MovingLinkButton key={route.href} tag={t(route.titleKey)} href={route.href}/>
                            )})}
                    </div>
                </Collapsible>
                <Collapsible title={t('images')}
                    icon={<ImagesIcon/>}>
                    <div className='grid grid-cols-2 gap-2 p-3'>
                        {Routes.PhotoRoutes.map(route => {
                            return(
                                <MovingLinkButton key={route.href} tag={t(route.titleKey)} href={route.href}/>
                            )})}
                    </div>
                </Collapsible>
                <Collapsible title={t('creators')}
                    icon={<CreatorsIcon/>}>
                    <div className='grid grid-cols-2 gap-2 p-3'>
                        {Routes.CreatorsRoutes.map(route => {
                            return(
                                <MovingLinkButton key={route.href} tag={t(route.titleKey)} href={route.href}/>
                            )})}
                    </div>
                </Collapsible>
                <Collapsible title={t('info')}
                    icon={<InfosIcon/>}>
                    <div className='grid h-full'> 
                    {Routes.InfoRoutes.map(route => {
                            return(
                                <MovingLinkButton key={route.href} tag={t(route.titleKey)} href={route.href}/>
                            )})}
                    </div>
                </Collapsible>
            </div>
        </div>
    )
}

export function Collapsible({title, children, icon}:{title:string, children:ReactNode, icon:ReactNode}){

    const [open, setOpen] = useState(false);

    return(
        <div className='flex flex-col gap-1'>
            <button onClick={() => setOpen(!open)} className='flex justify-between items-center w-full bg-base-100 rounded-md p-4 text-left'>
                <div className='flex items-center justify-center gap-2'>
                    {icon}
                    <h3 className="font-bold">{title}</h3>
                </div>
                {open? <CloseIcon className="stroke-[#3a3b3f] w-6 h-6"/>:<OpenIcon className="w-6 h-6"/>}
            </button>
            <div className={open?'w-full p-5 bg-base-100 rounded-md':'hidden'}>
                {children}
            </div>
        </div>
    )
}