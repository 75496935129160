import axios from './axios';
import { MediaListItem } from './users';

export const getMedia = (slug: string) => {
    return axios.get<MediaListItem>(`media/watch/${slug}`, {
        validateStatus: function (status) {
            return status == 200 || status == 404;
          }
    });
}

export enum MediaSearchOrder {
    Top = 'top',
    MonthlyTop = 'monthlyTop',
    WeeklyTop = 'weeklyTop',
    Trending = 'trending',
    Latest = 'latest',
    Oldest = 'oldest',
  }

  export enum MediaType {
    Video = 'video',
    Photo = 'photo',
    Gallery = 'gallery',
  }

export interface MediaFilters {
    verified?: boolean;
    vertical?: boolean;
    horizontal?: boolean;
    price?: number;
    transcoding?: boolean;
    approved?: boolean;
}
  
export interface MediaSearchQuery extends MediaFilters {
    order?: MediaSearchOrder;
    
    type?: MediaType;
  
    count?: number;
  
    page?: number;
  
    username?: string;
  

    tags?: string[];
}

export type SearchMediaResponse = {
    media: MediaListItem[];
    totalMedia: number;
}
export const searchMedia = (query: MediaSearchQuery) => {
    return axios.get<SearchMediaResponse>(`media/search`, {
        params: query
    });
}

export interface MediaPatchBody {
    tags?: string[];

    title?: string;

    isApproved?: boolean;
}
export const patchMedia = (slug: string, patch: MediaPatchBody) => {
    return axios.patch<MediaListItem>(`media/${slug}`, patch);
}