import Create from '../../public/images/icons/upload.svg';

export const UploadDropzoneOverlay = () => {
    return (
        <div className='fixed inset-0 bg-base-100 bg-opacity-90 border-primary border-4 rounded-xl flex flex-col gap-y-4 items-center justify-center'
        style={{
            zIndex: 50000
        }}>
            <Create className="h-16 w-16 text-white stroke-[4px] animate-bounce" />
            <h3 className='text-3xl font-semibold text-center'>Drop Files To Upload...</h3>
        </div>
    )
}