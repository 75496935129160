import styles from './Unauthorized/AccountDropdown.module.css';
import Link from "next/link";
import { useCallback, useContext, useState } from "react";
import { useRouter } from "next/router";
import cls from 'clsx';
import { AppContext } from '../../contexts/AppContext';
import { UnderlinedButton } from '../Buttons/UnderlinedButton';
import { LoadingIconContainer } from '../Buttons/LoadingIconContainer';
import { IconContainer } from '../Buttons/IconContainer';
import { US, CN, JP, KR, FR, DE, IT, BR, RU, ES } from 'country-flag-icons/react/3x2';
import { languages } from '../../util/languages';
import setLanguage from 'next-translate/setLanguage'
import cookieCutter from 'cookie-cutter'

const FlagWidth = 36;
const FlagHeight = 36;

export interface LanguageDropdownProps {
}
export const LanguageDropdown = ({  }: LanguageDropdownProps) => {
    const router = useRouter();
    const { isScrolled } = useContext(AppContext);
    const [ enableHover, setHoverEnabled ] = useState(true)
    const { locale } = router;

    const Flags: any = {
        'en': <US width={FlagWidth} height={FlagHeight} />,
        'zh': <CN width={FlagWidth} height={FlagHeight} />,
        'zh-TW': <CN width={FlagWidth} height={FlagWidth} />,
        'ja': <JP width={FlagWidth} height={FlagHeight} />,
        'ko': <KR width={FlagWidth} height={FlagHeight} />,
        'fr': <FR width={FlagWidth} height={FlagWidth} />,
        'de': <DE width={FlagWidth} height={FlagWidth} />,
        'it': <IT width={FlagWidth} height={FlagWidth} />,
        'pt': <BR width={FlagWidth} height={FlagWidth} />,
        'ru': <RU width={FlagWidth} height={FlagWidth} />,
        'es': <ES width={FlagWidth} height={FlagWidth} />,

    }

    const handleSelected = () => {
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur()
        }

        setHoverEnabled(false);
      }

    const onInteractionEnded = () => {
        setHoverEnabled(true);
    }

    return (
        <div onMouseLeave={onInteractionEnded} onTouchEnd={onInteractionEnded} className={cls(`dropdown dropdown-bottom ${styles.account_dropdown}`, {
            ['dropdown-hover']: enableHover
        })}>
            <UnderlinedButton renderContainer={(props) => <label {...props} />} underlineType="secondary" tabIndex={0}>
                <LoadingIconContainer hideText={isScrolled} label={''} icon={Flags[locale?.toLowerCase() || ''] as any}  />
            </UnderlinedButton>
            
            <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-300 rounded-box w-52">
                {Object.entries(languages).map(([locale, language]) => {
                    return (
                        <li key={locale} onClick={() => {
                            cookieCutter.set('NEXT_LOCALE', locale);
                            setLanguage(locale, true);
                            handleSelected();
                        }}>
                            <button >
                                <IconContainer responsiveText={false} className="gap-x-2" icon={Flags[locale || '']} label={language} />
                            </button>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}