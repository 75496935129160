import { QueryErrorResetBoundary } from "@tanstack/react-query"
import { useRouter } from "next/router"
import { ErrorBoundary } from "react-error-boundary"
import { UnderlinedButton } from "../../Buttons/UnderlinedButton"
import { PageError } from "../PageError/PageError"

export const QueryError = ({ children }: any) => {
    const router = useRouter();

    return (
        <QueryErrorResetBoundary>
            {({ reset }) => (
                <ErrorBoundary
                    onReset={reset}
                    resetKeys={[router.query]}
                    fallbackRender={({ resetErrorBoundary }) => (
                        <PageError>
                            <button className="btn" onClick={() => resetErrorBoundary()}>Try again</button>
                        </PageError>
                    )}>
                    {children}
                </ErrorBoundary>
            )}
        </QueryErrorResetBoundary>
    )
}