import { NextAdapter } from "next-query-params";
import Router, { useRouter } from "next/router";
import React, { useState, useEffect, useMemo, useContext } from "react";
import { ArrayParam, QueryParamProvider, useQueryParam, withDefault } from "use-query-params";
import { useMediaParams } from "../components/Media/MediaFeed/useMediaParams";
import { AppContext } from "./AppContext";

export interface WatchContextType {
    searchTags: string[];
    setSearchTags: (searchTags: string[]) => void;
    addSearchTag: (name: string) => void;
}
export const WatchContext = React.createContext<WatchContextType>({
    searchTags: [],
    setSearchTags: () => {},
    addSearchTag: (name: string) => {
        Router.push(`/browse?tags=${name}`)
    },
});

export const Provider = (props: any) => {
    const [searchTags, setSearchTags] = useQueryParam('tags', withDefault(ArrayParam, []));
    const mediaParams = useMediaParams();
    const router = useRouter();

    const { setSearchOpen, setSearch } = useContext(AppContext);

    const addSearchTag = async (name: string) => {
        const existing = searchTags.find(t => t === name);

        if (existing) {
            return;
        }

        setSearchOpen(false);

        setSearch('');

        setSearchTags([...searchTags, name]);
        mediaParams.setPage(undefined);
        window.scrollTo(0, 0);
    }

    const value = {
        searchTags,
        setSearchTags,
        addSearchTag
    };

    return (
        <WatchContext.Provider value={value as WatchContextType}>
            {props.children}
        </WatchContext.Provider>
    );
}

export const WatchContextProvider = (props: any) =>   <Provider {...props} />;