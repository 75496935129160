import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { useMediaQuery } from "@react-hook/media-query";
import { NextAdapter } from "next-query-params";
import { useRouter } from "next/router";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { ArrayParam, QueryParamProvider, useQueryParam, withDefault } from "use-query-params";


export interface AppContextType {
    addSearchTag: (name: string) => void;
    open: boolean;
    setOpen: (open: boolean) => void;
    search: string;
    setSearch: (search: string) => void;
    searchOpen?: boolean;
    setSearchOpen: (searchOpen?: boolean) => void;
    isScrolled: boolean;
    setIsScrolled: (val: boolean) => void;
}
export const AppContext = React.createContext<AppContextType>({
    addSearchTag: () => {},
    open: false,
    setOpen: () => {},
    search: "",
    setSearch: () => {},
    searchOpen: false,
    setSearchOpen: () => {},
    isScrolled: false,
    setIsScrolled: () => {}
});

const Provider = (props: any) => {
    const [ open, setOpen ] = useState(false);
    const [ searchOpen, setSearchOpen ] = useState<boolean | undefined>();
    const [ search, setSearch ] = useState<string>('');
    const [ isScrolled, setIsScrolled ] = useState(false);
    const router = useRouter();
    const isDesktop = useMediaQuery('only screen and (min-width: 640px)');
    const prev = useRef<any>(undefined);

    useScrollPosition(({ prevPos, currPos }) => {
        if (prev.current !== undefined) {
            if (currPos.y > prevPos.y && prevPos.y > prev.current.y) {
                if (currPos.y > 76) {
                        setIsScrolled(true);
                }
            }
            else if (currPos.y < prevPos.y && prevPos.y < prev.current.y) {
                if (currPos.y < 76) {
                        setIsScrolled(false);
                }
            }
        }

        prev.current = prevPos;
      }, [isScrolled, searchOpen, isDesktop], undefined, true);

      const handleRouteChange = () => {
        setIsScrolled(false);
      }

      useEffect(() => {
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
          router.events.off('routeChangeComplete', handleRouteChange);
        };
      }, []);

    const value = {
        open,
        setOpen,
        search,
        setSearch,
        searchOpen,
        setSearchOpen,
        isScrolled,
        setIsScrolled
    };

    return (
        <AppContext.Provider value={value as AppContextType}>
            {props.children}
        </AppContext.Provider>
    );
}

export const AppContextProvider = (props: any) => <Provider {...props} />
