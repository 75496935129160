const title = 'Free XXX Porn Videos, Pics, Gallerys';
const description = `Huge, fast, free, porn library.  Upload your own content quickly and easily.  Top content rises to the top of the site.`;
const url = process.env.NEXT_PUBLIC_SITE_URL;

const config = {
    title,
    description,
    titleTemplate: '%s | TutuPorn',
    openGraph: {
      type: 'website',
      locale: 'en_IE',
      url,
      siteName: 'TutuPorn',
      image: [],
      title,
      description
    },
    twitter: {
      handle: '@PornTutu',
      site: '@site',
      cardType: 'summary_large_image',
    },
  };

  export default config;