import { SuggestResponse } from "@jconradi/tutu-porn-api-client/src/suggest";
import Image from 'next/image';
import { useRouter } from "next/router";
import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import { WatchContext } from "../../contexts/WatchContext";
import useTranslation from "next-translate/useTranslation";

export type SearchDropdownProps = {
    data?: SuggestResponse;
    clearSearch: () => void;
}
export const SearchDropdown = ({ data, clearSearch }: SearchDropdownProps) => {
    const router = useRouter();
    const { searchTags, addSearchTag } = useContext(WatchContext);
    const { setSearch } = useContext(AppContext);

    const { t } = useTranslation('common');

    const navigate = (href: string) => {
        clearSearch();
        router.push(href);
    }

    if (!data) {
        return null;
    }

    const filteredTags = data?.tags.filter(t => !searchTags?.some(s => s === t.name)) || [];
    let isEmpty = false;

    if (filteredTags.length == 0 && data?.users.length == 0) {
        isEmpty = true;
    }

    const onTagClick = (tag: any) => {
        setSearch('');
        addSearchTag(tag.name);
    }

    const onUserClick = (user: any) => {
        navigate(`/users/${user.username}`)
    }

    return (
        <div className="absolute text-black font-bold text-lg top-[60px] overscroll-none overflow-y-auto max-h-[calc(100vh-196px)] w-full bg-white rounded-2xl px-[16px] py-[24px]"
            style={{
                border: '1px solid #ccc',
            }}>
            <ul>
                {filteredTags.map(tag => {
                    return (
                        <SearchDropdownItem onMouseDown={() => onTagClick(tag)} onTouchStart={() => onTagClick(tag)} key={tag.slug}>{tag.name}</SearchDropdownItem>
                    )
                })}
                {isEmpty && <p className="text-center">{t('NoResultsFound')}</p>}
                {data?.users.map(user => {
                    return (
                        <SearchDropdownItem  onMouseDown={() => onUserClick(user)} onTouchStart={() => onUserClick(user)} key={user.username}>
                            <div className="p-[10px] rounded-[8px] flex flex-row items-center h-full">
                                <div className="bg-base-100 w-[32px] aspect-square relative overflow-hidden rounded-full 
                ring ring-primary ring-offset-base-100 ring-[3px]">
                                    <Image unoptimized priority width={48} height={48} src={user.avatar} alt="User Avatar" className="absolute inset-0 w-full h-full" />
                                </div>
                                <p className="truncate text-lg sm:text-base font-bold ml-[10px] mr-[5px]">@{user.username}</p>
                            </div>
                        </SearchDropdownItem>
                    )
                })}
            </ul>
        </div>
    )
}

const SearchDropdownItem = ({ children, ...rest }: any) => {
    return (
        <li className="px-[6px] py-[3px] cursor-pointer hover:text-primary" style={{
            borderBottom: '1px solid rgba(0,0,0,.1)',
            lineHeight: '56px'
        }} {...rest}>
            {children}
        </li>
    )
}