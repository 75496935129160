import Link from "next/link";
import { useRouter } from "next/router";
import cls from 'clsx';

const UnderLinedHover = ({href, label, ...rest}:{href:string, label:string, [key: string]: any}) => {
    const router = useRouter();

    const isActive = router.asPath.startsWith(href);
    
    return(
        <Link {...rest} href={href} 
        className={cls('group text-center relative px-2 font-bold text-[12px] hover:bg-white/[0.16] rounded-lg py-4', {
            ['bg-outline']: isActive
        })}>
            {label}
            <div className={cls("opacity-0 absolute bottom-1 left-0 right-0 mx-auto w-[80%] h-[5px] rounded-full bg-secondary group-hover:opacity-100", {
                ['opacity-100']: isActive
            })}/>
        </Link>
    )
}

export default UnderLinedHover;