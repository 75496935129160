export const VideoRoutes = [{
    href: '/browse?order=trending&type=video',
    titleKey: 'trending',
}, {
    href: '/browse?order=top&type=video',
    titleKey: 'mediaSearchOrder.allTimeTop',
}, {
    href: '/browse?order=monthlyTop&type=video',
    titleKey: 'mediaSearchOrder.monthlyTop',
}, {
    href: '/browse?order=weeklyTop&type=video',
    titleKey: 'mediaSearchOrder.weeklyTop',
}, {
    href: '/browse?order=latest&type=video',
    titleKey: 'mediaSearchOrder.newest',
}, {
    href: '/browse?order=top&type=gallery',
    titleKey: 'gallerys',
}];

export const PhotoRoutes = [{
    href: '/browse?order=trending&type=photo',
    titleKey: 'trending',
}, {
    href: '/browse?order=top&type=photo',
    titleKey: 'mediaSearchOrder.allTimeTop',
}, {
    href: '/browse?order=monthlyTop&type=photo',
    titleKey: 'mediaSearchOrder.monthlyTop',
}, {
    href: '/browse?order=weeklyTop&type=photo',
    titleKey: 'mediaSearchOrder.weeklyTop',
}, {
    href: '/browse?order=latest&type=photo',
    titleKey: 'mediaSearchOrder.newest',
}, {
    href: '/browse?order=top&type=gallery',
    titleKey: 'gallerys',
}];

export const CreatorsRoutes = [{
    href: '/creators?order=trending',
    titleKey: 'trending',
}, {
    href: '/creators?order=top',
    titleKey: 'mediaSearchOrder.allTimeTop',
}, {
    href: '/creators?order=latest',
    titleKey: 'creatorLinks.newest',
}];

export const InfoRoutes = [{
    href: '/submit-tags',
    titleKey: 'infoLinks.submitTags',
}, {
    href: '/guidelines',
    titleKey: 'infoLinks.guidelines',
}, {
    href: '/faq',
    titleKey: 'infoLinks.faq',
}, {
    href: '/terms',
    titleKey: 'infoLinks.terms',
}, {
    href: '/privacy',
    titleKey: 'infoLinks.privacy',
}, {
    href: '/dmca',
    titleKey: 'infoLinks.dmca',
}, {
    href: '/notice2257',
    titleKey: 'infoLinks.2257',
}]