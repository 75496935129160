import LoadingBar from 'react-top-loading-bar'
import Router from "next/router";
import { useEffect, useRef } from 'react';

let timeoutId: any;
let progressAnimating = false;

export const LayoutLoadingBar = () => {
    const ref = useRef<any>(null)

    const load = () => {
        stop();
        
        timeoutId = setTimeout(() => {
            ref.current.continuousStart()
            progressAnimating = true;
        }, 500)
    }

    const stop = () => {
        clearTimeout(timeoutId);

        if (ref.current && progressAnimating) {
            ref.current.complete()
        }

        progressAnimating = false;
    }

    useEffect(() => {
        Router.events.on("routeChangeStart", load);
        Router.events.on("routeChangeComplete", stop);
        Router.events.on("routeChangeError", stop);

        return () => {
            Router.events.off("routeChangeStart", load);
            Router.events.off("routeChangeComplete", stop);
            Router.events.off("routeChangeError", stop);
        }
    }, []);

    return (
        <LoadingBar ref={ref} color='#8c18f1' />
    )
}